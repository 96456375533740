<template>
  <div class="">
    <!-- Top Navigation -->
    <div class="ml-4">
      <back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <div class="row page-header-row m-0 pl-3 pr-3 pt-3 mt-2 mb-3">
      <div class="heading-container">
        <h1 class="ml-2 patient-name font-weight-600">
          Checklist Items
        </h1>
      </div>
    </div>
    <div class="pl-4 pr-4 mt-2 mb-4">
      <checklist-item-card
        ref="checklistItemCard"
        screen="checklist-item"
        :items-list="response.list"
        :show-patient-check-icon="false"
        :loader="loaders.items"
        :show-headers="false"
        :actions="actions"
        :rules="rules"
        :table-columns="tableColumns"
        @addChecklistItemRow="addChecklistItem"
        @addToUpdateList="addToUpdateList"
        @handleDelete="handleDelete"
      >
      </checklist-item-card>
      <base-button class="btn-primary-action pt-2 pl-5 pr-5 pb-2 br-4" @click="submitForm('checklistForm')">Save</base-button>
    </div>
  </div>
</template>

<script>
import ChecklistItemCard from "@/views/Components/Checklist/ChecklistItemCard";
import BackButton from "@/components/Router/BackButton";

export default {
  name: "ChecklistItems",
  components: {
    ChecklistItemCard,
    BackButton
  },
  data() {
    return {
      loaders: {
        items: false,
      },
      response: {
        list: [],
      },
      request: {
        list: [],
        toRemoveIds: [],
        toUpdate: [],
      },
      tableColumns: [
        {
          prop: "description",
          label: "Checklist Item",
          minWidth: 180,
        },
      ],
      rules: {
        description: {required: true, message: 'required', trigger: 'blur'}
      },
      actions: [
        {
          src: '/img/icons/buttons/remove.svg',
          action: 'delete',
          minWidth: 80,
        }
      ],
    }
  },
  methods: {
    submitForm(formName) {
      let counter = 0;
      let validForm = true;
      let errorCount = 0;
      while (counter < this.response.list.length) {
        this.$refs.checklistItemCard.$refs[formName][counter].validate((valid) => {
          if (!valid) {
            validForm = false;
            //As guided by QA.. Error notification will be displayed only once
            if(errorCount === 0) {
              this.$notify.error({
                title: 'Validation Error',
                message: 'Please enter the values in the checklist items',
              });
            }
            errorCount=1;
            return false;
          }
        });
        counter += 1;
      }
      if (validForm) {
        this.saveChecklistItems();
      }
    },
    handleDelete(event) {
      this.response.list.splice(event.index, 1);
      if (event.element.hasOwnProperty("id")) {
        this.request.toRemoveIds.push(event.element.id);
      }
    },
    addChecklistItem() {
      let checklistItem = {
        description: '',
        patient_can_mark: true,
      };
      this.request.list.push(checklistItem);
      this.response.list.push(checklistItem)
    },
    addToUpdateList(element) {
      if (element.hasOwnProperty("id")) {
        this.request.toUpdate.push(element);
      }
    },
    getChecklistItems() {
      let vm = this;
      const tempStage = this.$route.params.tempStage ?? -1;
      vm.loaders.items = true;
      axios.get(this.$store.getters.getBaseUrl + `/api/checklist/items?tempStage=${tempStage}`).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.response.list = [...new Map(response.data.data.map(item => [item['description'], item])).values()]
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Checklist Items',
          message: message
        });
      }).finally(() => {
        vm.loaders.items = false;
      });
    },
    saveChecklistItems() {
      console.log(this.request);
      let vm = this;
      vm.loaders.items = true;
      axios.post(this.$store.getters.getBaseUrl + `/api/checklist/items`, {
        checklistItemsToAdd: this.request.list,
        checklistItemsToUpdate: this.request.toUpdate,
        checklistItemsToRemove: this.request.toRemoveIds,
      }).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.$notify.success({
          title: 'Checklist',
          message: 'Checklist items updated'
        });
        vm.$router.back();
      }).catch(error => {
        const defaultMessage = 'Something went wrong please try again in few minutes.'
        const errorMessage = error.response ? error.response.data.errors ?
          vm.generateChecklistItemError(error.response.data.errors, defaultMessage) : error.response.data.message : defaultMessage
        vm.$notify.error({
          title: 'Checklist Items',
          message: errorMessage
        });
      }).finally(() => {
        vm.loaders.items = false;
      });
    },

    /**
     * Return error message
     * @param {Object} errorMessages - Error message object
     * @param {string} message - Default message
     * @returns string
     */
    generateChecklistItemError(errorMessages, message) {
      Object.keys(errorMessages).every(function (key) {
        if (key.includes('checklistItemsToAdd') || key.includes('checklistItemsToUpdate')) {
          message = 'Checklist item already exists.'
          return false
        }
      })
      return message
    },
  },
  mounted() {
    this.getChecklistItems();
  }
}
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  color: rgb(185, 185, 203) !important;
  background-color: #F1F1F5FF;
}

#add-row {
  background: #EDF0F8 0 0 no-repeat padding-box;
  box-shadow: none;
  border-radius: 2px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  opacity: 1;
}
</style>

<style>

</style>
