<template>
  <el-drawer
    title="Filter"
    :visible.sync="$store.state.showUserCategoryFiltersSidebar"
    direction="rtl"
    custom-class="demo-drawer"
    @closed="closeSideBar"
    ref="drawer"
  >
    <div class="demo-drawer__content">
      <div class="divFilters">
        <div class="form-group" v-if="showSurgeryDateFilter">
          <template>
            <base-select v-model="surgeryDate" :options="surgeryDateOptions" label="Surgery Date"></base-select>
            <base-select v-model="surgeryDateDuration" :options="surgeryDateDurationOptions" v-if="surgeryDate === 'yes'"></base-select>
          </template>
        </div>
        <p class="mt-1 filterMainLabel">By Patient Tag</p>
        <base-input v-for="category in categories" :key="category.id" :label="category.name" class="d-inline-block divFiltersSingle">


          <p @click="category.state = null;" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
          <el-select
            class="select-primary pagination-select"
            v-model=category.state
            placeholder="All"
          >
            <el-option
              class="select-primary"
              v-for="(subCategory , index) in category.user_sub_categories"
              :key="subCategory.id"
              :label="subCategory.name"
              :value="subCategory.id"
            >
            </el-option>
          </el-select>
        </base-input>

        <p v-if="showChecklistTemplate" class="mt-1 filterMainLabel">By Checklist Template</p>
        <base-input v-if="showChecklistTemplate" label="Templates" class="d-inline-block divFiltersSingle">
          <p @click="checklistTemplates.state = null;" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
          <el-select
            class="select-primary pagination-select"
            v-model=checklistTemplates.state
            placeholder="All"
          >
            <el-option
              class="select-primary"
              v-for="(template , index) in checklistTemplates.templates"
              :key="template.id"
              :label="`${template.name} (${checklistTabs[template.temp_stage]})`"
              :value="template.id"
            >
            </el-option>
          </el-select>
        </base-input>

        <div v-if="showAppActivity">
          <p class="mt-1 filterMainLabel">By App Activity</p>
          <base-input label="Mobile User" class="d-inline-block divFiltersSingle">
            <p @click="mobileOption = 0" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
            <el-select
              class="select-primary pagination-select"
              v-model=mobileOption
              placeholder="All"
            >
              <el-option
                class="select-primary"
                v-for="(mobileOption) in mobileUserOptions"
                :key="mobileOption.value"
                :label="mobileOption.title"
                :value="mobileOption.value"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>

        <div v-if="showStageAndSurgeryFilter">
          <p class="mt-1 filterMainLabel">By Patient Stage and Surgery Type</p>
          <base-input label="Current Stage" class="d-inline-block divFiltersSingle">
            <p @click="currentStage = ''" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
            <el-select
              class="select-primary pagination-select"
              v-model=currentStage
              placeholder="Current Stage"
            >
              <el-option
                class="select-primary"
                v-for="(stage , index) in stages"
                :key="index"
                :label="stage"
                :value="index"
              >
              </el-option>
            </el-select>
          </base-input>
          <base-input label="Type Of Surgery" class="d-inline-block divFiltersSingle">
            <p @click="surgeryType = ''" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
            <el-select
              class="select-primary pagination-select"
              v-model=surgeryType
              placeholder="Type Of Surgery"
            >
              <el-option
                class="select-primary"
                v-for="(surgeryType , index) in surgeryTypes"
                :key="index"
                :label="surgeryType"
                :value="index"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div v-if="showCurrentStageFilter">
          <p class="mt-1 filterMainLabel">By Patient Current Stage</p>
          <base-input label="Current Stage" class="d-inline-block divFiltersSingle">
            <p @click="currentStage = 0" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
            <el-select
              class="select-primary pagination-select"
              v-model=currentStage
              placeholder="All"
            >
              <el-option
                class="select-primary"
                v-for="(currentStageOption) in currentStageOptions"
                :key="currentStageOption.value"
                :label="currentStageOption.title"
                :value="currentStageOption.value"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>

        <div v-if="showSurgeryTypeOnlyFilter">
          <p class="mt-1 filterMainLabel">By Patient Surgery Type</p>
          <base-input label="Type Of Surgery" class="d-inline-block divFiltersSingle">
            <p @click="surgeryType = ''" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
            <el-select
              class="select-primary pagination-select"
              v-model=surgeryType
              placeholder="Type Of Surgery"
            >
              <el-option
                class="select-primary"
                key=""
                label="All"
                value=""
              />
              <el-option
                class="select-primary"
                v-for="(surgeryType , index) in surgeryTypes"
                :key="index"
                :label="surgeryType"
                :value="index"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>

      </div>
      <div class="row">
        <div class="col-md-12">

        </div>
      </div>

      <div class="demo-drawer__footer d-flex w-100 pt-2">
        <el-button class="sidebar-btn w-50 apply-btn ml-3 mr-2" @click="applyFilters" >Apply</el-button>
        <el-button class="sidebar-btn w-50 btn-clear-all mr-3 ml-2" @click="clearFilters">Clear All</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import {Drawer} from 'element-ui';
export default {
  props: {
    showChecklistTemplate: {
      type: Boolean,
      description: "Show Checklist Template filter ",
      default:false,
    },
    showAppActivity: {
      type: Boolean,
      description: "Show App Activity filter ",
      default:false,
    },
    showStageAndSurgeryFilter: {
      type: Boolean,
      description: "Show current stage and type of surgery filter",
      default:false,
    },
    stages: {
      type: [Array, Object],
      description: "Stages of patient",
      default: () => [],
    },
    surgeryTypes: {
      type: [Array, Object],
      description: "Surgery types",
      default: () => [],
    },
    showCurrentStageFilter: {
      type: Boolean,
      description: "Show temp/current stage filter ",
      default:false,
    },
    showSurgeryDateFilter: {
      type: Boolean,
      description: "Show surgery date filter",
      default: false
    },
    showSurgeryTypeOnlyFilter: {
      type: Boolean,
      description: "Show surgery type filter only",
      default: false
    }
  },
  name: "UserCategorySidebar",
  data(){
    return {
      closingUsingCustomButton: false,
      oldFilterState: {
        categories: {},
        checklistTemplateFilter: {},
        mobileOption: 0,
        currentStage: '',
        surgeryType: '',
        currentStageFilter: '',
        surgeryDate: '',
        surgeryDateDuration: ''
      },
      categories : [],
      defaultCategories : [],
      checklistTemplates: {
       state:'All',
        templates:[]
      },
      surgeryDate: '',
      surgeryDateDuration: '',
      checklistTabs: {
        1 : 'Pre-Op',
        2 : 'Post-Op',
        3 : 'Weight Management',
      },
      mobileUserOptions: [
        {
          title: 'All',
          value: 0
        },
        {
          title: 'Patient has never used the app.',
          value: 1
        },
        {
          title: 'Patient has not used the app in past two weeks.',
          value: 2
        },
        {
          title: 'Patient has used the app.',
          value: 3
        }
      ],
      mobileOption: 0,
      currentStage: '',
      surgeryType: '',
      currentStageOptions: [
        {title: 'All', value: 0},
        {title: 'Considering Surgery', value: 25},
        {title: 'Pre-Op', value: 50},
        {title: 'Post-Op', value: 75},
        {title: 'Non-Surgical', value: 150}
      ],
      surgeryDateOptions: [
        { label: 'All', value: '' },
        { label: 'Has Surgery Date', value: 'yes' },
        { label: 'No Surgery Date', value: 'no' },
      ],
      surgeryDateDurationOptions: [
        { label: 'Anytime', value: '' },
        { label: 'Prior 12 months', value: '-12 month' },
        { label: 'Prior 6 months', value: '-6 month' },
        { label: 'Prior 3 months', value: '-3 month' },
        { label: 'Last month', value: '-1 month' },
        { label: 'Next week', value: '+1 week' },
        { label: 'Next 2 weeks', value: '+2 week' },
        { label: 'Next 30 days', value: '+30 day' },
        { label: 'Next 60 days', value: '+60 day' },
      ],
    }
  },
  computed :  {
    getCategories(){
      return this.$store.getters.getUserCategories;
    },
    getDefaultCategories(){
      return this.$store.getters.getDefaultUserSubCategories;
    },
    getChecklistTemplates(){
      return this.$store.getters.getChecklistTemplateFilters;
    },
    categorySidebarVisibility() {
      return this.$store.state.showUserCategoryFiltersSidebar;
    }
  },

  watch : {
    getCategories(newVal, oldVal){
      this.categories = newVal;
    },
    getDefaultCategories(newVal, oldVal){
      this.defaultCategories = newVal;
      this.setDefaultCategories();
    },
    getChecklistTemplates(newVal, oldVal){
      this.checklistTemplates.templates = newVal;
    },
    surgeryDate(newVal, oldVal) {
      if (newVal != 'yes') {
        this.surgeryDateDuration = '';
      }
    },
    categorySidebarVisibility(newVal) {
      if (newVal) {
        this.closingUsingCustomButton = false;
        this.setOldFilterState();
      }
    }
  },

  methods : {
    setOldFilterState() {
      let categories =  JSON.stringify(this.categories);
      this.oldFilterState = {...this.oldFilterState,
        categories,
        checklistTemplateFilter: this.checklistTemplates.state,
        mobileOption: this.mobileOption,
        currentStage: this.currentStage,
        surgeryType: this.surgeryType,
        currentStageFilter:this.currentStage,
        surgeryDate: this.surgeryDate,
        surgeryDateDuration: this.surgeryDateDuration
      }
    },
    closeSideBar() {
      if (!this.closingUsingCustomButton) {
        for(let index in this.categories) {
          this.categories[index].state = JSON.parse(this.oldFilterState.categories)[index].state;
        }
        this.checklistTemplates.state = this.oldFilterState.checklistTemplateFilter;
        this.mobileOption = this.oldFilterState.mobileOption;
        this.currentStage = this.oldFilterState.currentStage;
        this.surgeryType = this.oldFilterState.surgeryType;
        this.surgeryDate = this.oldFilterState.surgeryDate;
        this.surgeryDateDuration = this.oldFilterState.surgeryDateDuration;
        this.$store.commit('hideUCFS');
      }
    },
    getFiltersCount() {
      let obj = [];
      this.categories.forEach(category => {
        if(category.state && !isNaN(category.state)){
          obj.push(category.state);
        }
      });
      let checklistTemplateFilter = this.getChecklistTemplateFilters();
      let filtersCount = 0;
      filtersCount = obj ? obj.length : 0;
      filtersCount += checklistTemplateFilter ? checklistTemplateFilter.length : 0
      filtersCount += this.mobileOption !== 0 ? 1 : 0;
      filtersCount += this.currentStage != '' ? 1 : 0;
      filtersCount += this.surgeryType != '' ? 1 : 0;
      filtersCount += this.surgeryDate != '' ? 1 : 0;
      return filtersCount;
    },

    applyFilters(){
      this.closingUsingCustomButton = true;
      let obj = [];
      this.categories.forEach(category => {
        if(category.state && !isNaN(category.state)){
          obj.push(category.state);
        }
      });
      let checklistTemplateFilter = this.getChecklistTemplateFilters();
      let filtersCount = this.getFiltersCount();
      this.$root.$emit('applyUserSubCategoryFilters',{
        obj,
        checklistTemplateFilter,
        mobileOption: this.mobileOption,
        currentStage: this.currentStage,
        surgeryType: this.surgeryType,
        currentStageFilter:this.currentStage,
        filtersCount,
        surgeryDate: this.surgeryDate,
        surgeryDateDuration: this.surgeryDateDuration
      });
      this.$store.commit('hideUCFS');
    },
    getChecklistTemplateFilters(){
      return this.checklistTemplates.state === null || this.checklistTemplates.state === 'All' ? null : [this.checklistTemplates.state];
    },
    clearFilters(){
      this.closingUsingCustomButton = true;
      this.categories.forEach(category => {
        if(category.state && !isNaN(category.state)){
          category.state = null;
        }
      });
      this.checklistTemplates.state = 'All'
      this.mobileOption = 0
      this.currentStage = ''
      this.surgeryType = ''
      this.surgeryDate = ''
      this.surgeryDateDuration = '';
      this.applyFilters()
      this.$store.commit('hideUCFS')
    },

    clearSurgeryDateFilter() {
      this.surgeryDate = ''
      this.surgeryDateDuration = '';
    },

    setDefaultCategories(){
      this.categories.forEach((category,index)=>{
        var i = this.defaultCategories.findIndex(dc=>{
          if(dc.user_category_id == category.id){
            return true;
          }
        });
        this.$set(this.categories[index], 'state', i!=-1 && this.defaultCategories ? this.defaultCategories[i].user_sub_category_id : 'All' );
      });
      this.$root.$emit('UserSubCategoryFilterCount', this.getFiltersCount());

    }
  },
}
</script>

<style scoped>
.demo-drawer__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.btn-clear-all {
  color: #32325D;
  box-shadow: 0 3px 2px #E9ECEF0D;
  border: 0.5px solid #BDC4CC;
}

.btn-clear-all:hover, .btn-clear-all:focus {
  background-color: white;
  color: #32325D;
}

.apply-btn, .apply-btn:focus, .apply-btn:hover {
  color: #fff;
  background-color: #2F597A;
  border-color: #2F597A;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.clear-txt {
  text-align: left;
  font: normal normal normal 9px/13px Open Sans;
  letter-spacing: 0px;
  color: #BB2255;
  cursor: pointer;
}

.sidebar-btn{
  font: normal normal normal 12px/17px Open Sans;
  padding: 12px 20px;
  border-radius: 2px;
}

.filterMainLabel{
  color: #525f7f;
  font-size: 0.975rem;
  font-weight: 600;
}
</style>

<style>
.demo-drawer .el-drawer__body {
  padding: 20px 5px 20px 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.demo-drawer .divFilters {
  flex: 1;
  -webkit-box-flex: 1;
  padding-right: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}

.demo-drawer .divFiltersSingle {
  width: 100%;
}

.demo-drawer .demo-drawer__footer {
  justify-content: center;
}

.el-drawer__wrapper .demo-drawer #el-drawer__title {
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #32325D;
  opacity: 1;
}

</style>
